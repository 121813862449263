import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 16,
    prop: 'email',
    name: 'input',
    label: i18n.t('email'),
    placeholder: i18n.t('email'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'password',
    name: 'input',
    label: i18n.t('password'),
    placeholder: i18n.t('password'),
    Object: {
    }
  }]
}
